<template>
  <div>
    <CForm @submit="submit" v-if="loaded">

      <CCard>
        <CCardBody>

          <CAlert color="success text-center" v-if="!_.isEmpty(sentMessage)" @update:show="sentMessage = ''">
            {{ sentMessage }}
          </CAlert>

          <div style="display: flex; justify-content: end;">
            <div :class="{ 'text-success': !product.blocked, 'text-muted': product.blocked }" class="text-bold" style="font-size: 12px;">{{ product.blocked ? $t('disabled') : $t('enabled') }}</div>
            &nbsp;
            <CSwitch
                class="mr-1"
                color="success"
                :checked="!product.blocked"
                variant="opposite"
                size="sm"
                @update:checked="toggleBlock"
            />
          </div>

          <div class="row">
            <div class="col-md-6 col-sm-12">
              <CInput
                  :label="$t('name')"
                  type="text"
                  v-model="product.name"
                  @keydown="resetErrors('name')"
                  :invalid-feedback="_.has(errors, 'name') ? errors.name : ''"
                  :is-valid="_.has(errors, 'name') ? false : null"
                  ref="name"
              >
              </CInput>
            </div>

            <div class="col-md-6 col-sm-12">
              <CInput
                  :label="$t('serial_number')"
                  type="text"
                  v-model="product.serial_number"
                  @keydown="resetErrors('serial_number')"
                  :invalid-feedback="_.has(errors, 'serial_number') ? errors.serial_number : ''"
                  :is-valid="_.has(errors, 'serial_number') ? false : null"
                  ref="serial_number"
              >
              </CInput>
            </div>

            <div class="col-md-6 col-sm-12">
              <CInput
                  :label="$t('cost_price')"
                  type="text"
                  v-model="product.cost_price"
                  @keydown="resetErrors('cost_price')"
                  :invalid-feedback="_.has(errors, 'cost_price') ? errors.cost_price : ''"
                  :is-valid="_.has(errors, 'cost_price') ? false : null"
                  ref="cost_price"
              >
              </CInput>
            </div>

            <div class="col-md-6 col-sm-12">
              <CInput
                  :label="$t('consumer_price')"
                  type="text"
                  v-model="product.consumer_price"
                  @keydown="resetErrors('consumer_price')"
                  :invalid-feedback="_.has(errors, 'consumer_price') ? errors.consumer_price : ''"
                  :is-valid="_.has(errors, 'consumer_price') ? false : null"
                  ref="consumer_price"
              >
              </CInput>

            </div>

            <div class="col-md-6 col-sm-12">
              <CTextarea
                  :label="$t('description')"
                  rows="2"
                  v-model="product.description"
                  @keydown="resetErrors('description')"
                  :invalid-feedback="_.has(errors, 'description') ? errors.description : ''"
                  :is-valid="_.has(errors, 'description') ? false : null"
                  ref="description"
              />
            </div>

            <div class="col-md-6 col-sm-12">
              <label>{{ $t('color') }}</label>
              <div :style="{ 'background-color': product.color }"
                   class="wy-product-color"></div>
              <div class="wy-color-palette-container">
                <div v-for="(color, colorIndex) in colors" :key="`color_${colorIndex}`"
                     :class="{ 'selected': color === product.color }"
                     :style="{ 'background-color': color }"
                     @click="setColor(color)"></div>
              </div>
              <div class="clearfix"></div>
              <div v-if="_.has(errors, 'color')" class="is-invalid text-danger">
                <small>{{errors.color }}</small>
              </div>
            </div>

          </div>

        </CCardBody>
        <CCardFooter>
          <div class="row">
            <div class="col">
              <CButton type="submit" size="sm" color="red" class="px-4">
                <CIcon name="cil-check-circle"/>
                {{ $t('save') }}
              </CButton>
            </div>
            <div class="col text-right">
<!--              <CButton type="reset" size="sm" color="secondary" class="px-4">-->
<!--                <CIcon name="cil-ban"/>-->
<!--                {{ $t('reset') }}-->
<!--              </CButton>-->
            </div>
          </div>
        </CCardFooter>
      </CCard>
    </CForm>
  </div>
</template>

<script>
import {getProduct, getEmptyProduct, insertProduct, updateProduct} from "../../../api/product";
import scrollToFirstClass from '../../../mixins/DashboardScrollToFirstClass';

export default {
  name: 'Product',
  mixins: [
    scrollToFirstClass,
  ],
  data() {
    return {
      colors: [
          '#C41117',
          '#82B74B',
          '#034F84',
          '#A68D36',
          '#FF6F3C',
          '#7C73E6',
          '#64C4ED',
          '#F6378F',
          '#497285',
          '#575151',
          '#FFB677',
          '#D5DEF5',
          '#ADF7D1',
          '#D1CEBD',
          '#EFD510',
      ],
      passwordIsVisible: false,
      passwordIsVisibleConfirmation: false,
      errors: {},
      error: '',
      sentMessage: '',
      //
      id: '',
      product: {},
      //
      isEdit: false,
      loaded: false,
    };
  },
  mounted() {
    this.tryGetProduct();
  },
  methods: {
    resetErrors(property) {
      this.sentMessage = '';
      if (!this._.isUndefined(property)) {
        delete this.errors[property];
      } else {
        this.errors = {};
        this.error = ''
      }
    },
    tryGetProduct() {
      if (this._.has(this.$route.params, 'product_id') && /^\d+$/.test(this.$route.params.product_id)) {
        this.isEdit = true;
        getProduct(this, this.$route.params.product_id, (response) => {
          this.product = response.product;
          this.loaded = true;
        }, (error) => {
          this.error = error.message;
        });
      } else {
        getEmptyProduct(this, (response) => {
          this.product = response.product;
          this.loaded = true;
        }, (error) => {
          this.error = error.message;
        });
      }
    },
    validate(callback) {
      let hasErrors = false;
      this.resetErrors();

      this.$forceUpdate();
      if (!hasErrors) {
        callback()
      }
    },
    submit(event) {
      if (this._.isFunction(event.preventDefault)) {
        event.preventDefault();
      }
      this.sentMessage = '';
      this.validate(() => {
        if (!this.isEdit) {
          insertProduct(this, this.product, (response) => {
            this.sentMessage = response.message;
            this.$router.push({name: 'Products'});
          }, (error) => {
            if (this._.has(error, 'errors')) {
              let i = 1;
              this._.forEach(error.errors, (item, key) => {
                this.errors[key] = item[0];
                if (i === this._.size(error.errors)) {
                  this.$forceUpdate();
                  this.scrollToFirstClass('is-invalid');
                }
                i += 1;
              });
            }
          });
        } else {
          updateProduct(this, this.product, (response) => {
            this.sentMessage = response.message;
            this.$router.push({name: 'Products'});
          }, (error) => {
            if (this._.has(error, 'errors')) {
              let i = 1;
              this._.forEach(error.errors, (item, key) => {
                this.errors[key] = item[0];
                if (i === this._.size(error.errors)) {
                  this.$forceUpdate();
                  this.scrollToFirstClass('is-invalid');
                }
                i += 1;
              });
            }
          });
        }
      });
    },
    toggleBlock() {
      this.product.blocked = !this.product.blocked;
    },
    validURL(url) {
      const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))|'+ // OR ip (v4) address
          'localhost'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
      return !!pattern.test(url);
    },
    deletePhone(index) {
      this.resetErrors();
      this.product.phones.splice(index, 1);
      this.$forceUpdate();
    },
    addPhone() {
      this.product.phones.push({
        name: '',
        number: '',
        id: 0,
      })
    },
    setColor(color) {
      this.product.color = color;
    },
  },
}
</script>

<style lang="scss">
.wy-product-address-block {
  background-color: #EEE;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  padding: 10px;

  .wy-product-address-block-title {
    background-color: #FFFFFF;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    padding: 5px 15px;
    display: inline-block;
    margin: 0 0 10px 0;
  }
}
.wy-product-phones-block {
  margin: 20px 0 0 0;
  background-color: #EEE;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  padding: 10px;
  position: relative;

  .wy-product-phones-block-title {
    background-color: #FFFFFF;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    padding: 5px 15px;
    display: inline-block;
    margin: 0 0 10px 0;
  }

  .table thead th {
    border-bottom-width: 0!important;
    border-top-width: 0!important;
  }
  .table tbody td {
    border-bottom-width: 0!important;
    border-top-width: 0!important;
  }

  .wy-product-phones-block-add {
    position: absolute;
    top: 10px;
    background-color: #FFFFFF;
    -webkit-border-radius: 90px;
    -moz-border-radius: 90px;
    border-radius: 90px;
    padding: 8px;
    cursor: pointer;
    [dir=ltr] & {
      right: 10px;
    }
    [dir=rtl] & {
      left: 10px;
    }
    transition: all 350ms ease;
    &:hover {
      background-color: #3462A5;
      i {
        color: #FFFFFF;
      }
    }
    i {
      transition: all 350ms ease;
      font-size: 20px;
    }
  }
}
.padding-left-0 {
  [dir=ltr] & {
    padding-left: 0;
  }
  [dir=rtl] & {
    padding-right: 0;
  }
}
.padding-right-1 {
  [dir=ltr] & {
    padding-right: 5px;
  }
  [dir=rtl] & {
    padding-left: 5px;
  }
}
.wy-color-palette-container {
  width: 240px;
  div {
    [dir=ltr] & {
      margin-right: 5px;
      float: left;
    }
    [dir=rtl] & {
      margin-left: 5px;
      float: right;
    }
    cursor: pointer;
    border: #FFFFFF 4px solid;
    display: block;
    width: 40px;
    height: 40px;
    position: relative;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    transition: border-color 400ms ease;
    &:hover {
      border-color: #DDDDDD;
    }
    &.selected {
      border-color: #000000;
    }
  }
}
.wy-product-color {
  border: #CCCCCC 1px dashed;
  width: 212px;
  height: 14px;
  margin-bottom: 10px;
  transition: background-color 400ms ease;
  [dir=ltr] & {
    margin-left: 4px;
  }
  [dir=rtl] & {
    margin-right: 4px;
  }
}
</style>
